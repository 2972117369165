@use 'src/styles/variables/colors';

.tabs {
  display: grid;
  gap: 12px;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.tab {
  background: colors.$white;
  border: 1px solid colors.$btn-primary-auxiliary;
  border-radius: 10px;
  color: colors.$grayscale-900;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  padding: 9.5px 20px;
  text-align: center;
  transition: 0.2s;
  user-select: none;

  &:not(.active):hover {
    background: colors.$btn-primary-auxiliary;
    border: 1px solid colors.$btn-secondary;
  }
}

.active {
  border: 1px solid colors.$btn-primary;
  cursor: default;
}
