@use 'src/styles/variables/colors';

.tile {
  align-items: center;
  background: colors.$btn-primary-auxiliary;
  border-radius: 10px;
  color: colors.$btn-primary;
  column-gap: 8px;
  display: flex;
  font-size: 14px;
  line-height: 21px;
  overflow: hidden;
  padding: 9.5px 20px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
}

.circle {
  @media (max-width: 639px) {
    display: none;
  }

  &::before {
    background: colors.$system-error;
    border-radius: 4px;
    content: '';
    display: block;
    height: 8px;
    width: 8px;
  }

  &.circleConnected::before {
    background: colors.$system-success;
  }
}

.dropdownHeader {
  border: 0;
  cursor: pointer;
}
