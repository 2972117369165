@use './variables/fonts';
@use './variables/colors';

* {
  box-sizing: border-box;
  font-family: inherit;
  font-weight: inherit;
}

html {
  color: colors.$black;
  font-family: fonts.$primary-font;
  font-size: #{fonts.$base-font-size}px;
  font-weight: 600;
}

body {
  margin: 0;
  overflow: hidden;
  padding: 0;
}

p {
  line-height: #{fonts.$base-line-height}px;
  margin: 0 0 16px;
}
