@use 'src/styles/variables/colors';

.root {
  align-items: center;
  background: colors.$btn-primary;
  border: 0;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(colors.$grayscale-900, 0.24);
  color: colors.$white;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  gap: 4px;
  justify-content: center;
  line-height: 24px;
  padding: 12px 20px;
  text-decoration: none;
  transition: 0.2s;
  user-select: none;

  &:hover {
    box-shadow: 0 4px 4px rgba(colors.$grayscale-900, 0.14);
  }

  &:active {
    box-shadow: none;
  }

  svg {
    display: block;
    height: 16px;
    width: 16px;
  }
}

.disabled {
  background: colors.$btn-primary-auxiliary;
  box-shadow: none;
  color: colors.$grayscale-300;
  cursor: default;

  &:hover {
    box-shadow: none;
  }
}

.root.secondary {
  background: transparent;
  box-shadow: none;
  color: #3353e8;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  padding: 0 20px;

  &:active,
  &:hover {
    color: #3a4c88;
  }
}
