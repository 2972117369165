.nofication {
  --nofication-bg-color: #f5f7fe;
  --nofication-border-color: #3353e8;
  --nofication-icon-color: #3353e8;

  align-items: flex-start;

  background-color: var(--nofication-bg-color);
  border: 1px solid var(--nofication-border-color);
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(2, 12, 45, 0.14);

  display: flex;
  gap: 8px;
  padding: 17px;
  position: relative;
  width: 307px;
}

.show {
  animation: showUp 0.6s ease-in-out both;
}

.hide {
  animation: hideDown 0.3s ease-in-out both;
}

.closeBtn {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  position: absolute;
  right: 11px;
  top: 11px;
}

.icon {
  color: var(--nofication-icon-color);
  display: block;
  height: 21px;
  width: 21px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.severity {
  color: #0b1c50;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  text-transform: uppercase;
}

.message {
  color: #0b1c50;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  margin: 0;

  a {
    color: #3353e8;
    text-decoration: underline;
  }
}

.info,
.submitting {
  --nofication-bg-color: #f5f7fe;
  --nofication-border-color: #3353e8;
  --nofication-icon-color: #3353e8;
}

.submitting {
  .icon {
    animation: rotate 0.6s ease-in-out infinite;
  }
}

.success {
  --nofication-bg-color: #e5f5f1;
  --nofication-border-color: #009d6e;
  --nofication-icon-color: #009d6e;
}

.warning {
  --nofication-bg-color: #fdf6ec;
  --nofication-border-color: #eaa53e;
  --nofication-icon-color: #eaa53e;
}

.error {
  --nofication-icon-color: #b01e2b;
  --nofication-bg-color: #f7e9ea;
  --nofication-border-color: #b01e2b;
}

@keyframes showUp {
  0% {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10%);
  }

  100% {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
}

@keyframes hideDown {
  0% {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10%);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
