@use 'src/styles/variables/colors';

.root {
  animation: window-appear 1s cubic-bezier(0.32, 0.04, 0, 1);
  background: colors.$white;
  border-radius: 24px;
  box-shadow: 0 8px 24px rgba(colors.$grayscale-900, 0.14);
  max-width: 375px;
  padding: 24px;
  position: relative;
  width: 100%;

  @media (max-width: 480px) {
    max-width: 100%;
  }
}

@keyframes window-appear {
  from {
    top: -20vh;
    transform: scale(1.2);
  }

  to {
    top: 0;
    transform: scale(1);
  }
}
