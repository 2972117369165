@use 'src/styles/variables/fonts';
@use 'src/styles/variables/colors';

.root {
  background: colors.$system-success-bg;
  border-radius: 4px;
  color: colors.$system-success;
  cursor: default;
  display: block;
  font-family: fonts.$secondary-font;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  overflow: hidden;
  padding: 7px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 639px) {
    display: none;
  }
}
