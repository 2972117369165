@use 'src/styles/variables/colors';

.root {
  width: 100%;
}

.inputContainer {
  position: relative;
}

.input {
  /* Firefox */
  appearance: textfield;
  background: colors.$white;
  border: 1px solid rgba(colors.$btn-primary, 0.2);
  border-radius: 12px;
  box-shadow: inset 0 0 0 1px rgba(colors.$btn-primary, 0.2);
  font-size: 14px;
  height: 56px;
  outline: none;
  padding-left: 16px;
  padding-right: 9px;
  transition: border 0.3s;
  width: 100%;

  &:focus {
    border: 1px solid colors.$btn-primary;
    box-shadow: 0 0 0 1px colors.$white;
  }
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
}

.inputWithIcon {
  padding-left: 47px;
}

.inputWithBtn {
  padding-right: 64px;
}

.leftIcon {
  height: 24px;
  left: 16px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
}

.maxBtn {
  background: transparent;
  border: 0;
  border-radius: 4px;
  color: colors.$btn-primary;
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 21px;
  padding: 3px 8px;
  position: absolute;
  right: 9px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s;
  user-select: none;

  &:hover {
    background: colors.$btn-secondary;
    color: colors.$grayscale-900;
  }
}

.caption {
  background: rgba(colors.$btn-primary, 0.2);
  border-radius: 0 0 12px 12px;
  color: colors.$grayscale-700;
  font-size: 12px;
  font-weight: 400;
  margin-top: -12px;
  padding: 16px 16px 4px;

  strong {
    font-weight: 600;
  }
}
