@use 'src/styles/variables/colors.scss';

.root {
  align-items: center;
  column-gap: 8px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 3000px;
  width: 100%;
}

.leftSide {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.logo {
  color: colors.$grayscale-900;
  display: block;
  height: 24px;
  margin-right: 16px;
}

.rightSide {
  align-items: center;
  column-gap: 8px;
  display: flex;
  height: 48px;
}
