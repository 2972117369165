@use 'src/styles/variables/colors';

.root {
  background-color: colors.$grayscale-100;
  border-radius: 12px;
  display: flex;
  filter: drop-shadow(0 8px 24px rgba(2, 12, 45, 0.14));
  flex-direction: column;
  margin-top: 2px;
  overflow: hidden;
}

.icon {
  color: inherit;
  display: inline-block;
  height: 24px;
  margin-bottom: 5px;
  margin-right: 8px;
  vertical-align: middle;
  width: 24px;
}

.option {
  background-color: colors.$grayscale-100;
  border: 0;
  color: colors.$grayscale-900;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  line-height: 56px;
  margin: 0;
  min-width: 200px;
  overflow: hidden;
  padding: 0 16px;
  text-align: left;
  text-overflow: ellipsis;
  transition: 0.1s;
  user-select: none;
  white-space: nowrap;
  width: auto;

  &:hover {
    background-color: colors.$btn-primary;
    color: colors.$white;
  }
}

.optionRed {
  color: colors.$system-error;
}

.line {
  background: colors.$grayscale-200;
  height: 1px;
  margin: 4px 0;
  padding: 0;
  width: 100%;
}
