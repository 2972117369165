$white: #fff;
$black: #000;

$btn-primary: #3353e8;
$btn-primary-auxiliary: #f5f7fe;
$btn-secondary: #99adf5;

$system-success: #009d6e;
$system-success-bg: #e5f5f1;
$system-error: #b01e2b;

$grayscale-100: #f9f9fb;
$grayscale-200: #dadde7;
$grayscale-300: #bcc1d2;
$grayscale-400: #9da5be;
$grayscale-500: #7e88a9;
$grayscale-700: #3a4c88;
$grayscale-900: #020c2d;
