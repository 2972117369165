.notifications {
  bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  right: 36px;
  z-index: 100;

  @media (max-width: 480px) {
    bottom: 24px;
  }
}
