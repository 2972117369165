$min-width: 670px;
$min-height: 600px;

.root {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100vw;
  z-index: 1;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: $min-height;
  min-width: $min-width;

  @media (max-width: 639px) {
    min-height: auto;
    min-width: auto;
  }
}

.header {
  flex: 0 0 auto;
  padding: 24px 64px;

  @media (max-width: 639px) {
    padding: 24px;
  }
}

.main {
  display: block;
  flex: 1 0 auto;
  padding: 24px 64px;

  @media (max-width: 639px) {
    padding: 24px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
}

.bgMedia {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center top;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}
