.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip::before {
  background: #020c2d;
  border-radius: 10px;
  color: #fff;
  content: attr(data-tooltip-text);
  filter: drop-shadow(0 6px 15px rgba(2, 12, 45, 0.24));

  left: 100%;
  margin-left: 15px;

  opacity: 0;
  padding: 8px 16px;
  pointer-events: none;
  position: absolute;
  text-align: center;

  top: 50%;
  transform: translateY(-50%);

  transition: all 0.35s ease-in-out;
  width: 176px;
}

.tooltip::after {
  border: 10px solid #020c2d;
  border-color: transparent #020c2d transparent transparent;
  content: '';

  left: 100%;
  margin-left: -5px;

  opacity: 0;
  pointer-events: none;
  position: absolute;

  top: 50%;
  transform: translateY(-50%);
  transition: all 0.35s ease-in-out;
}

.tooltip:hover::before,
.tooltip:hover::after {
  opacity: 1;
}
